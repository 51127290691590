import { routes } from "../config/api_routes";

export const changePasswordService = {
    changePassword: (formData) =>
        window.axios.post(routes.changePasswordApi, formData),

    forgotPassword: (formData) =>
        window.axios.post(routes.forgotPasswordApi, formData),

    clinetForgotPassword: (formData) =>
        window.axios.post(routes.clientForgotPasswordApi, formData),
};
