<script>
import config from "../config";
import { replaceByDefault } from "../Helper/helper";
import { settingService } from "../services";
export default {
  data() {
    return {
      config: config,
      replaceByDefault: replaceByDefault,
      app_logo: "",
    };
  },
  mounted() {
    this.getSetting();
  },
  methods: {
    getSetting() {
      settingService.getSetting().then((response) => {
        if (response.data.code === 200) {
          this.app_logo = response.data.result.master_admin_app_logo;
        } else {
          error_message(response.data.message);
        }
      });
    },
  },
};
</script>
<template>
  <div class="navbar-brand-box navbar-brand-m" v-if="$auth.user.vendor_role == '0'">
    <a href="#" class="logo logo-dark" v-if="$auth.setting">
      <span class="logo-sm">
        <img
          :src="config.foodicon + $auth.setting.flat_icon"
          @error="replaceByDefault"
          alt
          height="22"
        />
      </span>
      <span class="logo-lg">
        <img :src="config.foodicon + $auth.setting.app_logo" alt height="17" />
      </span>
    </a>

    <a href="#" class="logo logo-light" v-if="$auth.setting">
      <span class="logo-sm">
        <img :src="config.foodicon + $auth.setting.flat_icon" alt height="22" />
      </span>
      <span class="logo-lg">
        <img
          :src="config.foodicon + $auth.setting.app_logo"
          @error="$event.target.src = config.no_image"
          alt
          height="25"
        />
      </span>
    </a>
  </div>
  <div class="navbar-brand-box navbar-brand-m" v-else>
    <a href="#" class="logo logo-dark" v-if="$auth.setting">
      <span class="logo-sm">
        <img
          :src="config.foodicon + $auth.setting.flat_icon"
          @error="replaceByDefault"
          alt
          height="22"
        />
      </span>
      <span class="logo-lg" v-if="$auth.isDashboardLogoActive()">
        <img
          v-if="$auth.isDashboardLogoActive()"
          :src="`${config.foodicon}${$auth.setting.app_logo}`"
          alt="Dashboard Logo"
          height="17"
        />
      </span>
      <span class="logo-lg" v-else-if="app_logo">
        <img :src="`${config.foodicon}${app_logo}`" alt="Default Logo" height="17" />
      </span>
      <span class="logo-lg" v-else> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    </a>

    <a href="#" class="logo logo-light" v-if="$auth.setting">
      <span class="logo-sm">
        <img :src="config.foodicon + $auth.setting.flat_icon" alt height="22" />
      </span>
      <span class="logo-lg">
        <img
          :src="config.foodicon + $auth.setting.app_logo"
          @error="$event.target.src = config.no_image"
          alt
          height="25"
        />
      </span>
    </a>
  </div>
</template>
