
// Client billing services rest api calling

import { routes } from '../config/api_routes'

export const clientBillingService = {
    // client billing
    getClientBilling: (formData) => {
        return window.axios.post(routes.getClientBillingApi,formData)
    },
    
}